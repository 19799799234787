import axios from 'axios'
import Store from '../../store'
import { errorHandler, successHandler } from '../helpers/handler'
import { BASEURL } from '../const'

const URL = `${BASEURL}/transfers/user`

export default {
  data() {
    return {
      getTransfers: async () => {
        const endpoint = `${URL}`
        try {
          const res = await axios.get(endpoint, {
            headers: {
              ...Store.getters['user/getRequestHeaders'].headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      getTransfer: async (transferId) => {
        const endpoint = `${URL}/${transferId}`
        try {
          const res = await axios.get(endpoint, {
            headers: {
              ...Store.getters['user/getRequestHeaders'].headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      makeTransfer: async ({ amount, sendDetails, otp }) => {
        const endpoint = `${URL}`
        const data = { amount, send_details: sendDetails, otp }
        try {
          const res = await axios.post(endpoint, data, {
            headers: {
              ...Store.getters['user/getRequestHeaders'].headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      }
    }
  }
}
