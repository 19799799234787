<template>
  <v-container>
    <v-btn text color="accent" @click="$router.go(-1)" class="mb-2">
      <v-icon left> mdi-arrow-left-thick </v-icon>
      Back
    </v-btn>
    <h1>Transfer to your Snappy Exchange Friend</h1>
    <p class="text-subtitle-1 font-weight-bold">
      Got questions? Call or Whatsapp {{ siteData.phone }}
    </p>
    <v-row>
      <v-col cols="12" md="6">
        <v-form ref="transferForm" @submit.prevent="validate()">
          <v-card :loading="loadingTransfer" flat>
            <v-container>
              <v-card-title
                >Transfer Fund To Your Snappy Exchange Friend</v-card-title
              >
              <v-card-text>
                <p>
                  Your currently have
                  <b class="primary--text">
                    {{ userCurrency }} {{ wallet.balance || 0 }}
                  </b>
                  in your wallet.
                </p>

                <p>
                  To transfer fund from your wallet to your friend wallet on
                  snappy exchange, enter your friend username and proceed to
                  enter amount to transfer.
                </p>

                <p>
                  To ensure safe delivery of fund to user, please make sure your
                  friend username that you are transferring money to is valid
                </p>
                <v-text-field
                  v-model="username"
                  filled
                  :rules="[(v) => v.length > 0 || 'Username is required']"
                  :disabled="loadingTransfer"
                  label="Enter your friend's username"
                ></v-text-field>
                <v-text-field
                  filled
                  :disabled="loadingTransfer"
                  number
                  v-model="amount"
                  type="number"
                  :rules="[
                    (v) =>
                      (v && v <= (wallet.balance || 0)) ||
                      'You don\'t have that much in your wallet',
                    (v) => v > 0 || `Please input a valid amount`
                  ]"
                  label="Amount"
                  hint="Amount to Transfer to user"
                  persistent-hint
                ></v-text-field>
                <v-text-field
                  v-if="user.authenticationType === 'otp'"
                  v-model="otp"
                  filled
                  label="OTP"
                  hint="An OTP  will be sent to your registered email to approve this transaction"
                  persistent-hint
                >
                  <template v-slot:append-outer>
                    <v-btn
                      :loading="sendingOtp"
                      @click="generateOtp"
                      color="primary"
                      dark
                    >
                      Request OTP
                    </v-btn>
                  </template>
                </v-text-field>
                <v-text-field
                  v-if="user.authenticationType === 'pin'"
                  v-model="transactionPin"
                  filled
                  label="PIN"
                  hint="Enter your PIN"
                  persistent-hint
                >
                </v-text-field>
                <p>
                  After this transfer is approved you will have
                  <b class="primary--text"
                    >{{ userCurrency }}
                    {{ (Number(wallet.balance) || 0) - Number(amount) }}</b
                  >
                  left in your wallet
                </p>
                <v-btn
                  block
                  type="submit"
                  :loading="loadingTransfer"
                  :disabled="amount <= 0"
                  large
                  color="primary"
                >
                  Transfer
                </v-btn>
              </v-card-text>
            </v-container>
          </v-card>
        </v-form>
      </v-col>
      <v-col cols="12" md="6">
        <v-card flat>
          <v-toolbar flat
            ><v-tabs v-model="logsTab">
              <v-tab> Approved </v-tab>
              <v-tab>
                <v-badge
                  :value="pendingTransfers.length > 0"
                  color="pink"
                  :content="pendingTransfers.length"
                >
                  Pending
                </v-badge></v-tab
              >
              <v-tab> Declined</v-tab>
            </v-tabs></v-toolbar
          >
          <v-sheet>
            <v-tabs-items v-model="logsTab">
              <v-tab-item>
                <v-data-table
                  :loading="loading"
                  :footer-props="{
                    itemsPerPageOptions: [5, 10, 15, 25, 50, 100, -1]
                  }"
                  item-key="_id"
                  :items="approvedTransfers"
                  :headers="headers"
                >
                  <template v-slot:[`item.amount`]="{ item }">
                    <span
                      >{{ item.amount }} <small>{{ userCurrency }}</small>
                    </span>
                  </template>
                  <template v-slot:[`item.status`]="{ item }">
                    <v-card
                      :color="
                        item.status == 'approved'
                          ? 'success'
                          : item.status == 'pending'
                          ? 'warning'
                          : 'error'
                      "
                      flat
                      rounded="md"
                      outlined
                      class="text-capitalize text-center white--text pa-1"
                    >
                      {{ item.status }}
                    </v-card>
                  </template>
                  <template v-slot:no-data>
                    <div class="py-3 text-center">
                      <v-img
                        src="@/assets/img/undraw_empty.svg"
                        width="325"
                        class="mx-auto"
                        alt="empty"
                      />
                      <h2 class="mt-3">No approved Transfer</h2>
                    </div>
                  </template>
                </v-data-table>
              </v-tab-item>
              <v-tab-item>
                <v-data-table
                  :loading="loading"
                  :footer-props="{
                    itemsPerPageOptions: [5, 10, 15, 25, 50, 100, -1]
                  }"
                  item-key="_id"
                  :items="pendingTransfers"
                  :headers="headers"
                >
                  <template v-slot:[`item.amount`]="{ item }">
                    <span
                      >{{ item.amount }} <small>{{ userCurrency }}</small>
                    </span>
                  </template>
                  <template v-slot:[`item.status`]="{ item }">
                    <v-card
                      :color="
                        item.status == 'approved'
                          ? 'success'
                          : item.status == 'pending'
                          ? 'warning'
                          : 'error'
                      "
                      flat
                      rounded="md"
                      outlined
                      class="text-capitalize text-center white--text pa-1"
                    >
                      {{ item.status }}
                    </v-card>
                  </template>
                  <template v-slot:no-data>
                    <div class="py-3 text-center">
                      <v-img
                        src="@/assets/img/undraw_empty.svg"
                        width="325"
                        class="mx-auto"
                        alt="empty"
                      />
                      <h2 class="mt-3">No pending Transfer</h2>
                    </div>
                  </template>
                </v-data-table>
              </v-tab-item>
              <v-tab-item>
                <v-data-table
                  :loading="loading"
                  :footer-props="{
                    itemsPerPageOptions: [5, 10, 15, 25, 50, 100, -1]
                  }"
                  item-key="_id"
                  :items="declinedTransfers"
                  :headers="headers"
                >
                  <template v-slot:[`item.amount`]="{ item }">
                    <span
                      >{{ item.amount }} <small>{{ userCurrency }}</small>
                    </span>
                  </template>
                  <template v-slot:[`item.status`]="{ item }">
                    <v-card
                      :color="
                        item.status == 'approved'
                          ? 'success'
                          : item.status == 'pending'
                          ? 'warning'
                          : 'error'
                      "
                      flat
                      rounded="md"
                      outlined
                      class="text-capitalize text-center white--text pa-1"
                    >
                      {{ item.status }}
                    </v-card>
                  </template>
                  <template v-slot:no-data>
                    <div class="py-3 text-center">
                      <v-img
                        src="@/assets/img/undraw_empty.svg"
                        width="325"
                        class="mx-auto"
                        alt="empty"
                      />
                      <h2 class="mt-3">No declined Transfer</h2>
                    </div>
                  </template>
                </v-data-table>
              </v-tab-item>
            </v-tabs-items>
          </v-sheet>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import misc from '../../../api/users/misc'
import transferApi from '../../../api/users/transfer'

export default {
  data() {
    return {
      logsTab: 1,
      amount: '',
      loading: false,
      username: '',
      loadingTransfer: false,
      otp: '',
      sendingOtp: false,
      usePin: false,
      transactionPin: '',
      headers: [
        {
          value: 'transaction_id',
          text: 'Tranx No',
          sortable: false
        },
        {
          value: 'amount',
          text: 'Amount'
        },
        {
          value: 'send_details',
          text: 'Receiver'
        },
        {
          value: 'status',
          text: 'Status'
        }
      ],
      transfers: []
    }
  },
  computed: {
    pendingTransfers() {
      if (this.transfers === []) return []
      return this.transfers.filter((transfer) => transfer.status === 'pending')
    },
    approvedTransfers() {
      if (this.transfers === []) return []
      return this.transfers.filter((transfer) => transfer.status === 'approved')
    },
    declinedTransfers() {
      if (this.transfers === []) return []
      return this.transfers.filter((transfer) => transfer.status === 'declined')
    },
    ...mapState({
      wallet: (state) => state.user.wallet,
      user: ({ user }) => user.user,
      siteData: (state) => state.user.siteSettings
    })
  },
  created() {
    this.getTransfers()
  },
  methods: {
    validate() {
      if (this.$refs.transferForm.validate()) this.makeTransfer()
    },
    async getTransfers() {
      this.loading = true
      const res = await transferApi.data().getTransfers()
      this.transfers = res.data.data.data.filter(
        (d) => d.user === this.$store.state.user.user._id
      )
      this.loading = false
    },
    async makeTransfer() {
      this.loadingTransfer = true

      const payload = {
        amount: this.amount,
        sendDetails: this.username
      }

      if (this.user.authenticationType === 'otp') {
        payload.otp = this.otp
      } else {
        payload.transactionPin = this.transactionPin
      }

      const res = await transferApi.data().makeTransfer(payload)
      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          error: true,
          status: true
        })
        this.loadingTransfer = false
        return
      }
      this.$store.dispatch('alert', {
        message: 'Transfer request sent successfully',
        status: true
      })
      this.loadingTransfer = false
      this.amount = ''
      this.username = ''
      this.getTransfers()
      this.$router.push('/user/dashboard')
    },
    async generateOtp() {
      this.sendingOtp = true
      const res = await misc.misc().generateOtp('transfer')
      if (res.error) {
        this.$store.dispatch('alert', {
          message: res.errorMessage.message || res.internalError.message,
          error: true,
          status: true
        })
        this.sendingOtp = false
        return
      }
      this.$store.dispatch('alert', {
        message: 'OTP sent successfully, kindly check your email',
        status: true
      })
      this.sendingOtp = false
    }
  }
}
</script>

<style></style>
